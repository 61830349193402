@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import "../node_modules/angular-calendar/css/angular-calendar.css";
$blue: #4c579b;
* {
	outline: none !important;
	font-weight: 300;
	font-size: 14px;
}

:host {
	display: block;
}

body,
html {
	padding: 0;
	margin: 0;
	background: #f4f4f4;
}

.btn {
	border-radius: 0 !important;
	&.btn-danger {
		i,
		.fa,
		* {
			color: #fff;
		}
	}
}

.angular-editor-textarea {
	min-height: 200px !important;
}

.mat-slide-toggle.mat-checked {
	.mat-slide-toggle-thumb {
		background-color: #67a82c;
	}
	.mat-slide-toggle-bar {
		background-color: rgba(67, 129, 11, 0.42);
	}
}

.white-box {
	background: #fff;
	padding: 20px;
	margin: 10px 0;
	border: 1px solid #f1f1f1;
	border-bottom: 3px solid #e8e6e6;
	border-radius: 8px;
	position: relative;
	.footer-white-box {
		padding: 15px 20px;
		border-top: 1px solid #ddd;
		margin: 20px -20px -20px -20px;
		background: #e5e5e524;
		text-transform: uppercase;
		position: relative;
		border-radius: 0 0 4px 4px;
		cursor: pointer;
		transition: 0.3s ease all;
		&:hover {
			color: #fff;
			background: #1976d2;
		}
		&:hover .fa {
			color: #fff;
		}
		.fa {
			position: absolute;
			top: 15px;
			right: 25px;
			font-size: 20px;
			color: #1182cd;
		}
	}
	&.pd-10 {
		padding: 10px 20px
	}
	&.no-pd {
		padding: 0;
	}
	.open-modal {
		position: absolute;
		color: #b5b5b5;
		right: 16px;
		top: 19px;
		cursor: pointer;
		font-size: 20px;
		z-index: 999;
		background: #fff;
		height: 30px;
		width: 30px;
		border-radius: 8px;
		text-align: center;
		line-height: 30px;
	}
	&.padd30 {
		padding: 30px;
	}
	&.nopd {
		min-height: 150px;
		padding: 0;
		p {
			padding: 30px;
		}
	}
	&.content {
		min-height: 200px;
	}
}

.carousel-inner {
	border-radius: 8px;
}

#page-content-wrapper {
	min-width: calc(100vw - 15rem);
	margin-left: 15rem;
	&>.container-fluid {
		padding-top: 60px;
	}
	@media (max-width: 768px) {
		min-width: 0;
		width: 100%;
		margin-left: 0 !important;
	}
}

h2.title {
	letter-spacing: 0.5px;
	color: #1471d4;
	text-transform: uppercase;
	font-size: 18px;
	margin-bottom: 25px;
	margin-top: 5px;
	font-weight: 700;
}
.main-title {
	img {
		height: 30px;
	}
}
.fa.grey {
	color: #b4b4b4;
	margin-right: 7px;
}

.table {
	width: 100%;
	th {
		background: rgba(0, 0, 0, 0.02);
	}
	td, th {
		padding:10px 13px !important;
		white-space: nowrap;
	}
} 


table.info {
	width: 100%;
	td {
		padding: 7px 10px;
		i.fa-star {
			color: #309dc6;
			font-size: 20px;
			margin-left: 3px;
		}
	}
	tr:nth-child(2n+1) {
		background: #fafafa;
	}
	td:first-child:not(.icon) {
		padding-right: 20px;
		min-width: 30%;
	}
	td.icon {
		width: 40px;
		text-align: center
	}
	.dis-onhover {
		visibility: hidden
	}
	tr:hover {
		.dis-onhover {
			visibility: visible;
		}
	}
}

.form-group {
	.mat-form-field {
		display: block;
		width: 100%;
	}
}

.box-scroll,
.note-editable {
	overflow-y: auto;
	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
		background-color: #F5F5F5;
		border-radius: 100px;
	}
	&::-webkit-scrollbar:horizontal {
		height: 6px;
	}
	&::-webkit-scrollbar {
		width: 6px;
		background-color: #F5F5F5;
		border-radius: 100px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #1977dc;
		opacity: 0.9;
		border-radius: 100px;
	}
	&.minth {
		&::-webkit-scrollbar-track {
			-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
		}
		&::-webkit-scrollbar-thumb {
			background-color: rgba(25, 118, 210, 0.5);
		}
	}
}

sidebar-menu.box-scroll,
.note-editable {
	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.35);
	}
	&::-webkit-scrollbar-thumb {
		background-color: rgba(25, 118, 210, 0.7);
	}
}

.bold-scroll.box-scroll {
	&::-webkit-scrollbar-thumb {
		background-color: rgba(25, 118, 210, 0.3);
	}
	&::-webkit-scrollbar:horizontal {
		height: 9px;
	}
	&::-webkit-scrollbar {
		width: 9px;
	}
}

@for $i from 1 through 10 {
	.h-#{$i * 50} {
		height: #{$i * 50}px;
	}
}

.vertical-align {
	display: flex;
	align-items: center;
	justify-content: center;
}

.profil-icon {
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	.img {
		height: 50px;
		width: 50px;
		margin-right: 15px;
		overflow: hidden;
		display: inline-block;
		border-radius: 100%;
		img {
			max-width: 100%;
			height: initial;
		}
	}
	span {
		font-size: 14px;
		font-weight: 400;
		line-height: 17px;
		small {
			display: block;
			font-weight: 400;
			font-size: 12px;
		}
	}
	button {}
}

.row {
	min-width: 100%;
}

.link-first {
	text-decoration: underline;
	cursor: pointer;
}

.btn.btn-success {
	background: #38a9c2;
	border-color: #38a9c2;
	outline: none;
	box-shadow: none;
	color: #fff;
	&:focus {
		background: #38a9c2;
		border-color: #38a9c2;
		outline: none;
		box-shadow: none;
	}
	&:hover {
		background: #1976d2;
		border-color: #1976d2;
		outline: none;
		box-shadow: none;
	}
}

.btn-inline {
	&.text-right .btn {
		margin-left: 10px;
	}
	&:not(.text-right) .btn {
		margin-right: 10px;
	}
}
.relative {
	position: relative;
}
.mat-datepicker-toggle {
	button {
		position: absolute;
		right: 20px;
		top: 24px;		
	}

}
/* The container-checkbox */

.container-checkbox {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 16px;
	user-select: none;
	line-height: 25px;
	margin: 30px 0 20px 0;
}


/* Hide the browser's default checkbox */

.container-checkbox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}


/* Create a custom checkbox */

.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;
	background-color: #eee;
}


/* On mouse-over, add a grey background color */

.container-checkbox:hover input~.checkmark {
	background-color: #ccc;
}


/* When the checkbox is checked, add a blue background */

.container-checkbox input:checked~.checkmark {
	background-color: #2196F3;
}


/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}


/* Show the checkmark when checked */

.container-checkbox input:checked~.checkmark:after {
	display: block;
}


/* Style the checkmark/indicator */

.container-checkbox .checkmark:after {
	left: 9px;
	top: 6px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.flex-vertical {
	display: flex;
	align-items: center;
	justify-content: center;
}

.flex-end {
	justify-content: flex-end;
}

.mat-expansion-panel-body {
	padding: 17px 40px 16px !important;
	background: #fff;
	border-bottom: 1px solid #ddd;
	margin: 0 -40px 0 -30px;
}

.mat-tab-body-content {
	padding-top: 15px;
	min-height: 50px;
}

.mat-tab-label {
	border: 1px solid #ddd;
	margin-right: 5px;
	margin-bottom: -1px;
	&.mat-tab-label-active {
		opacity: 1
	}
}

.btn {
	outline: none !important;
	box-shadow: none !important;
}

.btn-default {
	border: 1px solid #ddd !important;
}

.btn+.btn {
	margin-left: 10px;
}

.cdk-drag-preview {
	box-sizing: border-box;
	background: #ddd;
}

.cdk-drag-placeholder {
	opacity: 0;
}

.cdk-drag-animating {
	transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.mat-dialog-actions {
	margin-bottom: -15px !important;
}

.btn-danger {
	background-color: #e64857 !important;
}

.btn {
	padding-left: 15px;
	padding-right: 15px;
}

.img-responsive {
	max-width: 100%;
}

.upr {
	overflow: hidden;
}

.mat-drawer-container {
	height: 100%;
}

.mat-progress-spinner circle,
.mat-spinner circle {
	opacity: 0.7;
	stroke: #1976d2;
}

.mrgb-20 {
	margin-bottom: 20px;
}

.mat-dialog-title {
	position: relative;
	color: #266dad;
	text-transform: uppercase;
	&.with-trans {
		padding-right: 180px;
	}
}

.icons-select {
	.mat-option {
		height: 70px !important
	}
}

.fixed-lang-config {
	position: absolute;
	right: 0;
	top: 0;
	width: 150px !important;
}

.mat-tab-body-content {
	overflow-x: hidden !important;
}

.hidden {
	display: none !important;
}

.now-active {
	label {
		color: green;
		font-weight: 500 !important;
	}
	input,
	textarea,
	select {
		border: 1px solid green;
	}
}

.modal-md {
	min-width: 600px;
}

.mat-option .mat-pseudo-checkbox-checked::after {
	top: 2.4px;
	left: 2px;
	width: 8px;
	height: 5px;
	border-left: 2px solid currentColor;
	transform: rotate(-45deg);
	opacity: 1;
}

.form-group {
	margin-bottom: 23px !important;
}

.form-group .file {
	position: relative;
	margin-top: 10px;
	img.img-responsive {
		border: 1px solid #ddd;
		padding: 10px;
		max-height: 250px;
		max-width: 100%;
		width: initial;
		height: initial;
	}
	.btn-danger {
		position: absolute;
		top: -5px;
		left: -5px;
		border-radius: 100%;
		font-size: 14px;
		padding: 0;
		height: 30px;
		width: 30px;
	}
}

.mat-drawer-inner-container {
	background: #33394F !important;
	a.mat-list-item {
		color: #fff;
		height: 50px;
		.fa {
			margin-right: 15px
		}
	}
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
	height: 50px;
	margin-top: 14px;
}

.notifier__notification--material .notifier__notification-message {
	font-weight: 400;
}

.accordion>.card:last-of-type {
	border-bottom: 1px solid #ddd !important;
}

.mat-dialog-container {
	.error.mat-dialog-title {
		color: #e64857
	}
	p {
		font-size: 19px;
	}
}

.custom-file-label {
	white-space: nowrap;
	overflow: hidden;
}

.select2-container--default {
	&.select2 {
		display: block;
		width: 100% !important;
		.select2-selection {
			border: 1px solid #ddd !important;
		}
	}
	.select2-search--inline .select2-search__field {
		width: 100% !important;
	}
	.select2-selection--multiple .select2-selection__choice {
		background-color: #37a8c2;
		border-radius: 4px;
		cursor: default;
		border: 0;
		float: left;
		margin-right: 5px;
		margin-top: 7px;
		padding: 2px 14px;
		color: #fff;
		font-weight: 400;
		.select2-selection__choice__remove {
			color: #fbfbfb;
			margin-right: 12px;
		}
	}
}

.label {
	padding-top: 10px;
}

.mat-dialog-container {
	position: relative;
}

.absoluted.visible {
	// position: absolute;
	// top:10px;
	// right: 150px;
	// width: 80px;	
}

.item .view-details {
	margin: -16px -16px -16px -16px;
	width: calc(100% + 32px);
}

.mat-menu-content {
	min-width: 170px;
}

.overlay.ng-star-inserted {
	opacity: 0.7;
}

.api-test {
	.overlay.ng-star-inserted {
		opacity: 0.9;
	}
}

.cal-month-view .cal-day-badge {
	background: #007bff
}

.select2-container--default {
	.select2-selection--single .select2-selection__arrow {
		top: 6px;
		right: 4px;
	}
	&.select2 .select2-selection {
		min-height: 40px;
	}
}

.cal-month-view .cal-header .cal-cell {
	background: #f1f1f1;
	border-top: 1px solid #ddd;
}

.box-chart {
	.highcharts-legend-item,
	.highcharts-legend {
		display: none;
	}
}

.dialog-error .mat-dialog-container {
	background: #e64857;
	color: #fff;
	text-align: center;
}

.form-control {
	border-radius: 0 !important;
}

.title-modal-hardware * {
	font-size: 17px;
	font-weight: 600
}

.faq-content,
.searched {
	h3 {
		span {
			background: #ffd7405c;
			font-weight: inherit;
			font-size: inherit;
		}
	}
	p {
		span {
			background: #ffd7405c;
			font-size: inherit;
		}
	}
}


/** wysiwig **/

.fr-box.fr-basic.fr-top .fr-wrapper {
	border-radius: 0 0 10px 10px;
	border-color: #ccc !important;
}

.ng-star-inserted .second-toolbar {
	display: none;
}

.ck.ck-button.ck-on,
a.ck.ck-button.ck-on {
	background: transparent !important;
	color: #2c9ac6;
}

.ck.ck-toolbar {
	background: #fff;
	border-radius: 6px 6px 0 0 !important;
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
	border-radius: 0 0 6px 6px !important;
}

.nice-scroll::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px #4d5881;
	background-color: #F5F5F5;
}

.nice-scroll::-webkit-scrollbar {
	width: 6px;
	background-color: #F5F5F5;
}

.nice-scroll::-webkit-scrollbar-thumb {
	background-color: #4d5881;
}

.page-config {
	.mat-tab-header {
		order: 2;
	}
	mat-tab-group {
		margin: -15px -20px 0 0;
	}
	.mat-tab-list {
		height: calc(100vh - 64px);
		overflow: hidden;
		background: #2d9bc6;
	}
	.mat-tab-label {
		min-width: initial;
		border: 0;
		color: #fff;
		border-bottom: 1px solid #22566b;
		border-top: 1px solid #2ea5d3;
		margin: 0;
	}
}

.list-apis {
	.mat-tab-label {
		height: 36px;
		width: 50%;
		margin-top: 10px;
		&:last-child {
			margin-right: 0;
		}
	}
	.mat-tab-body-content {
		padding-top: 0;
		margin-top: -1px;
		margin-bottom: 1px;
		table {
			margin-top: 10px;
			th {
				padding: 3px 10px;
			}
			td {
				padding: 5px;
				border: 0;
				&:last-child {
					width: 84px !important;
				}
				&.other {
					.container-checkbox {
						text-align: left;
						margin: 5px;
						font-size: 15px;
					}
				}
				.container-checkbox {
					text-align: left;
				}
				&:not(.other) .container-checkbox {
					padding-left: 0;
					margin: 3px;
				}
			}
			.form-control {
				height: 30px;
				font-size: 13px;
			}
		}
	}
}

.iframe {
	font-size: 14px;
	font-weight: 400;
	// background: var(--danger);
	*:not(h1):not(h2):not(h3) {
		font-size: 12px;
	}
	&>div {
		padding: 10px 15px;
		border-bottom: 1px solid #ddd;
		* {
			font-weight: 400;
		}
		&:first-child {
			padding: 10px 15px;
			background: #ffc;
		}
		&:nth-child(2) {
			padding: 10px 15px;
			background: #f6f6f6;
		}
		&::nth-child(3) {
			padding: 10px 15px;
			background: #eee;
			border-bottom: 0px none;
		}
	}
	h1 {
		font-weight: normal;
		margin-bottom: .4em;
	}
	h1 span {
		font-size: 60%;
		color: #666;
		font-weight: normal;
	}
	table {
		border: none;
		border-collapse: collapse;
		width: 100%;
	}
	td,
	th {
		vertical-align: top;
		padding: 2px 3px;
	}
	th {
		width: 12em;
		text-align: right;
		color: #666;
		padding-right: .5em;
	}
	#info {
		background: #f6f6f6;
		padding: 15px;
	}
	#info ol {
		margin: 0.5em 4em;
	}
	#info ol li {
		font-family: monospace;
	}
	#summary {
		background: #ffc;
		padding: 15px;
	}
	#explanation {
		background: #eee;
		border-bottom: 0px none;
	}
}

.my-code-editor {
	.editor-container {
		height: calc(100vh - 100px);
	}
}

ngx-json-viewer * {
	font-size: 12px;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
	background-color: #2196f4;
}

.error-dialog mat-dialog-container {
	background: hsl(358, 82%, 64%);
	p {
		color: #fff
	}
	* {
		text-align: center;
	}
}

.supervision {
	tr.mat-footer-row,
	tr.mat-row {
		height: initial !important;
	}
}

table {
	th:first-letter,
	th .mat-sort-header-button:first-letter,
	th *:first-letter {
		text-transform: uppercase !important;
	}
	td {
		vertical-align: middle !important;
		.btn-xs {
			border-radius: 4px !important;
		}
	}
}

.btn-xs {
	padding: 3px 10px;
	font-size: 12px;
	font-weight: 500;
}

app-table-supervison {
	width: 100%;
	display: block;
}

.mat-sort-header-arrow {
	color: #107bd0;
}

app-html-editor {
	.dropdown-toggle::after {
		display: none;
	}
}

.note-modal-footer {
	height: 60px;
	padding: 10px 30px;
	text-align: center;
}

.note-modal-header {
	padding: 10px 20px 10px 20px;
	border: 1px solid #ededef;
}

.note-modal-header .close {
	margin-top: 4px;
}

.note-editor.note-frame {
	border: 1px solid #ddd;
}

.note-editor.note-frame .note-statusbar {
	background-color: #fff;
}

.note-popover .note-popover-content,
.note-toolbar {
	padding: 0 140px 5px 5px;
	margin: 0;
	background-color: #fff;
}

.form-control.inline {
	display: inline-block;
	max-width: 200px;
}

.no-mrg table {
	margin-top: 0 !important;
}

.mrgt-20 {
	margin-top: 20px;
}

.mrgt-30 {
	margin-top: 30px;
}

.enviroment-config .mat-expansion-panel-body {
	padding: 0 15px 15px 15px !important;
	margin: 0;
}

// .ace_editor {
// 	font-size: 15px;
//     line-height: 17px;
// }
.alert * {
	color: #721c24 !important;
	.segment-key {}
}

.ng-dirty.ng-invalid {
	border-color: red;
}

body>.mat-row img {
	width: 25px
}

.mat-expansion-panel:not([class*=mat-elevation-z]) {
	border-bottom: 2px solid #e8e6e6;
	border-radius: 6px;
	box-shadow: none;
}

.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]).cdk-keyboard-focused,
.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]).cdk-program-focused,
.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]):hover {
	background-color: #fff;
}

.mat-expansion-panel-header {
	min-height: 60px;
}

.active-notif {
	&.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
		background-color: rgb(27, 121, 155);
	}
	&.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
		background-color: #3F51B5;
	}
}

.mat-tooltip {
	font-size: 13px;
}

tr.mat-header-row {
	height: 49px !important;
	background: #f5f5f540;
	th {
		color: #33394f;
		button {
			font-weight: 400;
		}
	}
}

.without-header {
	.mat-tab-header {
		display: none;
	}
	.mat-tab-body-content {
		padding: 0
	}
}

app-media-manager {
	display: block;
	width: 100%;
}

.highcharts-credits {
	display: none;
}

.mat-dialog-content {
	padding-top: 20px !important;
	border-top: 1px solid #ddd;
}

.mat-dialog-title {
	margin: 0 0 15px;
}

hotel-sliders:hover {
	.carousel-control-prev {
		background: linear-gradient(90deg, #76787e75, transparent);
	}
	.carousel-control-next {
		background: linear-gradient(90deg, transparent, #76787e75);
	}
}

.error-form {
	label {
		color: #E91E63
	}
	.form-control {
		border-color: #E91E63;
	}
}

#crop-media-manager .cropper {
	outline: #ffffff85 solid 100vw !important;
}

image-cropper {
	padding: 0 !important;
}

.mat-tooltip {
	background: #000;
}

.form-control.transparent {
	border: 0;
	background: transparent;
	color: #fff;
	font-weight: bold;
	text-align: center;
}

.mat-step-label {
	width: 100%;
}

.loader {
	div {
		height: 40px;
		border-bottom: 1px solid #dddddd4d;
		animation-duration: 2.8s;
		animation-fill-mode: forwards;
		animation-iteration-count: infinite;
		animation-name: placeHolderShimmer;
		animation-timing-function: linear;
		background: #f6f7f8;
		background: linear-gradient(to right, rgba(0, 0, 0, 0.02) 8%, rgba(0, 0, 0, 0.06) 38%, rgba(0, 0, 0, .02) 54%);
		background-size: 1000px 640px;
		position: relative;
		&:nth-child(2) {
			animation-name: placeHolderShimmer2
		}
	}
	@keyframes placeHolderShimmer {
		0% {
			background-position: -468px 0
		}
		100% {
			background-position: 468px 0
		}
	}
	@keyframes placeHolderShimmer2 {
		0% {
			background-position: -300px 0
		}
		100% {
			background-position: 750px 0
		}
	}
}

.card-header h3 {
	margin: 0;
	cursor: pointer;
	font-size: 15px;
	font-weight: 400;
}

.cdk-drop-list-dragging>div:not(.cdk-drag-placeholder) {
	transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.custom-placeholder {
	background: #ccc;
	border: dotted 1px #999;
	min-height: 60px;
	transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.variable {
	font-size: 15px;
	font-weight: 400;
	&:before {
		content: "{{";
		font-weight: 400;
		margin-left: 5px;
		display: inline-block;
	}
	&::after {
		content: "}}";
		font-weight: 400;
		margin-right: 5px;
		display: inline-block;
	}
}

.flex-between {
	display: flex;
	justify-content: space-between;
}


.white-box  h1  {
    font-size: 26px;
    color: #4c579b;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    line-height: 20px;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: var(--cyan);
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: var(--cyan);
}
.dp-input {
	padding: .25rem .5rem;
	font-size: .875rem;
	line-height: 1.5;
	background: rgb(238, 238, 238);
	border: 1px solid #d1d1d1;
	border-radius: 3px 0px 0px 3px;
	height: 33px;
  }
  
  .dp-group {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	width: 100%;
	&.inline {
		display: inline;
	}
  }
  
  .dp-button {
	cursor: pointer;
	margin-left: 0px;
	display: inline-block;
	text-align: center;
	vertical-align: middle;
	user-select: none;
	background-color: transparent;
	border: 1px solid #d1d1d1;
	border-left: 0px;
	padding: .375rem .75rem;
	font-size: 1rem;
	line-height: 1.5;
	box-shadow: 1px 1px 2px 0px #EEE;
	border-radius: 0px 3px 3px 0px;
  }
  